// 节流函数 
export function throttle(func, limit) {
    let inThrottle;
    return function() {
      const args = arguments;
      const context = this;
  
      if (!inThrottle) {
        func.apply(context, args);
        inThrottle = true;
  
        setTimeout(() => {
          inThrottle = false;
        }, limit);
      }
    };
  }

  // 防抖函数
export function debounce(func, wait) {
    let timeout = null;
    
    return function(...args) {
      const context = this; // 保存当前上下文
      
      if (timeout !== null) {
        clearTimeout(timeout); // 清除上一次的定时器
      }
      
      timeout = setTimeout(() => {
        func.apply(context, args); // 在指定时间后调用函数
      }, wait);
    };
  }