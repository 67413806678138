import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// const routes = [
//   {
//     path: '/',
//     name: 'home',
//     component: ()=> import('@/views/HomeView.vue')
//   },
//   {
//     path: '/introduce',
//     name: 'introduce',
//     component: ()=> import('@/views/Introduce.vue')
//   },
//   {
//     path: '/radio',
//     name: 'radio',
//     component: ()=> import('@/views/WatchRadio.vue')
//   },
//   {
//     path: '/analysis',
//     name: 'analysis',
//     component: ()=> import('@/views/ownDataAly.vue')
//   },
//   {
//     path: '/login',
//     name: 'login',
//     component: ()=> import('@/views/Login.vue')
//   },
//   {
//     path: '/my',
//     name: 'myInfo',
//     component: ()=> import('@/views/MyInfo.vue')
//   },
//   {
//     path: '/text',
//     name: 'text',
//     component: ()=> import('@/views/Text.vue')
//   }
// ]
const routes = [
  {
    path: '/',  // 程序启动默认路由
    component: () => import('@/components/Whole.vue'),
    meta: { title: '整体页面布局' },
    redirect: '/industry',  // 重定向到首页
    children: [
      {
        path: '/industry',
        component: () => import('@/views/industry/index.vue'),
        meta: { title: '产业分布' },
        redirect: '/industry/index',  // 该配置是若点击选择一级菜单时，默认选中并跳转到该一级菜单下的第一个二级菜单
        children:[
          {
            path: 'index',
            component: () => import('@/views/industry/index1-1.vue'),
            meta: { title: '产业发展分析' },
          },
          {
            path: 'ranking',
            component: () => import('@/views/industry/ranking.vue'),
            meta : { title: '企业五百强'},
          },
        ]
      },
      {
        path: '/business',
        component: () => import('@/views/business/index.vue'),
        meta: { title: '企业投资' },
        redirect: '/business/index',     // 该配置是若点击选择父目录时，默认选中该父目录下的子路径页面
        children:[
          {
            path: 'index',
            component: () => import('@/views/business/index2-1.vue'),
            meta: { title: '精准企业投资' },
          }
        ]
      },
      {
        path: '/owndata',
        component: () => import('@/views/owndata/index.vue'),
        meta: { title: '自有数据分析' }
      },
    ]
  },
  {
    path: '/info',
    component: () => import('@/views/info/index.vue'),
    meta: { title: '个人信息'},
    redirect: '/ownInfo',
    children:[
      {
        path: '/ownInfo',
        component:() => import('@/views/info/ownInfo.vue'),
        meta: { title: '个人信息'}
      },
      {
        path: '/cloudCoin',
        component:() => import('@/views/info/cloudCoin.vue'),
        meta: { title:'云币扣除记录' },
      },
      {
        path: '/feedback',
        component:() => import('@/views/info/feedback.vue'),
        meta: { title:"反馈中心" }
      },
      {
        path: '/infoEdit',
        component: () => import('@/views/info/infoEdit.vue'),
        meta: { title: '信息修改' },
      },
      {
        path: '/passwordDeng',
        component: () => import('@/views/info/passwordDeng.vue'),
        meta: { title: '登录密码' }
      },
      {
        path: '/phoneBang',
        component:() => import('@/views/info/phoneBang.vue'),
        meta: { title: '绑定手机'}
      },
      {
        path: '/headPicture',
        component: () => import('@/views/info/editHeadPic.vue'),
        meta: {title: '修改头像'}
      },
      {
        path: '/editEmail',
        component: () => import('@/views/info/editEmail.vue'),
        meta: {title: '修改邮箱'}
      },
      {
        path: '/loginRecord',
        component: () => import('@/views/info/loginRecord.vue'),
        meta: {title: '登录记录'}
      },
      {
        path: '/order',
        component: () => import('@/views/info/order.vue'),
        meta: {title: '订单中心'}
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: ()=> import('@/views/Login.vue')
  },
  {
    path: '/my',
    name: 'myInfo',
    component: ()=> import('@/views/MyInfo.vue')
  },
  {
    path: '/member',
    name: 'member',
    component: ()=> import('@/views/Member.vue')
  },
  {
    path: '/qrcodeWei',
    name: 'qrcodeWei',
    component: ()=> import('@/components/qrcodeWei.vue')
  },
  {
    path: '/introduce',
    name: 'introduce',
    component: ()=> import('@/views/Introduce.vue')
  },
  {
    path: '/radio',
    name: 'radio',
    component: ()=> import('@/views/WatchRadio.vue')
  },
  {
    path: '/text',
    name: 'text',
    component: ()=> import('@/views/Text.vue')
  },
  {
    path: '/pay',
    name: 'payment',
    component: () => import('@/views/paymentSucess.vue')
  }
]
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
  routes
})
// router.beforeEach((to, from, next) => {
//   const isLoggedIn = JSON.parse(window.localStorage.getItem("loginInfo"));
//   if (to.path === '/login') {
//       if (isLoggedIn) {
//           // 如果已经登录，重定向到导航页面（可根据实际需求调整）
//           next('/');
//       } else {
//           next();
//       }
//   } else if (to.path === '/introduce' || to.path === '/radio' || to.path === '/analysis' || to.path === '/my' || to.path === '/text') {
//       if (!isLoggedIn) {
//           // 如果未登录且访问需要登录的页面，重定向到登录页面
//           if (from.path!== '/login') {
//               next('/login');
//           } else {
//               // 如果当前页面已经是登录页面，不进行重定向
//               next(false);
//           }
//       } else {
//           next();
//       }
//   } else {
//       next();
//   }
// });


export default router
